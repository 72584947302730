.title {
    font-size: rem(34px);
    font-weight: 900;

    @media (max-width: $mantine-breakpoint-sm) {
        font-size: rem(24px);
    }
}

.description {
    max-width: rem(1200px);
    margin: auto;
    text-align: center;

    @media (max-width: $mantine-breakpoint-xs) {
        text-align: left;
    }

    &::after {
        content: '';
        display: block;
        background-color: var(--mantine-color-yellow-filled);
        width: rem(45px);
        height: rem(2px);
        margin-top: var(--mantine-spacing-sm);
        margin-left: auto;
        margin-right: auto;
    }
}

.card {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition:
            transform 150ms ease,
            box-shadow 100ms ease;
    padding: var(--mantine-spacing-xl);
    padding-left: calc(var(--mantine-spacing-xl) * 2);

    @mixin hover {
        box-shadow: var(--mantine-shadow-md);
        transform: scale(1.02);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: rem(6px);
        background-image: linear-gradient(
                0,
                var(--mantine-color-pink-6),
                var(--mantine-color-orange-6)
        );
    }
}

.labelFix{
    color: var(--mantine-color-dimmed);
}